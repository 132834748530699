<template>
  <div>
    <asignaturasList />
  </div>
</template>

<script>
import asignaturasList from './components/AsignaturasList.vue'

export default {
  components: {
    asignaturasList,
  },
}
</script>

<style>

</style>
